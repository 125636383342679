body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body, html, #root, .App, .slider-container {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}

.stretchy-wrapper img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}


.slick-slide img, .slick-slide video, .slick-slide div {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.layout-single-01{
  width: 350px;
   height: 210px;
    background-color: black;
   
}
.layout-single-01-extend{
  width: 100%;
 
  height: -webkit-fill-available;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
    
}
.layout-two-row-01{
  width: 350px;
   height: 160px;
    background-color: #E1F7F5;
  
}
.layout-two-row-01-extend{
  width: 100%;
  height: 88.89%;
 
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}
.layout-two-row-02{
  width: 350px;
   height: 50px;
    background-color: #E1F7F5;
   
  
}
.layout-two-row-02-extend{
  width: 100%;

  height: 11.11%;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}
.layout-two-col-01{
   width: 245px;
    height: 210px;
     background-color: black;
     
}
.layout-two-col-02 {
  width: 105px;
  height: 210px;
  background-color: black;

  border-left: none; /* Sol bordürü kaldırır */
}
.layout-two-col-01-extend{
  width: 72.14%;

  height: -webkit-fill-available;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}
.layout-two-col-02-extend{
  width: 27.86%;
 
  height:-webkit-fill-available;
  
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.device-number-container {
  height: 100vh;
}

.pairing-code {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 28vmin;
  font-weight: 100;
  margin: 0 0 20vmin 0;
  text-align: center;
  overflow-wrap: break-word;
}

.pairing-line-1 {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 8vmin;
  font-weight: 100;
  margin: 0;
  text-decoration: none;
}

.pairing-line-2 {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 4vmin;
  font-weight: 100;
  margin: 20px 0 0 0;
}

.device-number-logo {
  width: 50px;
  height: 50px;
  margin-top: 50px;
}
